<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col>
                    <v-select
                        v-model="select"
                        :items="plantItems"
                        :label="$t('project.sensors.whatToPlant')"
                        item-text="label"
                        item-value="value"
                        return-object
                        @input="handleChangePlant($event)"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row v-if="!selectedFlowers">
                <v-col>
                    <span>
                        {{ $t("project.sensors.wantSensor") }}
                    </span>
                    <v-radio-group v-model="addingSensor" row>
                        <v-radio label="Θέλω" :value="true"></v-radio>
                        <v-radio label="Όχι" :value="false"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>

            <v-row v-if="!selectedFlowers && addingSensor">
                <v-col>
                    <v-row>
                        <v-col>
                            <p class="text-body-2">
                                 {{ $t(`project.sensors.defaultSensor`) }}
                                <b>{{ $t(`project.sensors.defaultSensorName`) }}</b>
                            </p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div v-if="!sensorLocation">
                                <p class="text-body-2">
                                    {{ $t("project.sensors.location") }}
                                </p>
                                <v-btn
                                    color="garden"
                                    class="my-3 white--text"
                                    depressed
                                    @click="getGeolocation()"
                                >
                                    {{ $t("project.sensors.findLocation") }}
                                </v-btn>
                            </div>
                            <div v-else>
                                {{ $t("project.sensors.locationSuccess") }}
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="accessKey"
                                label="Πρόσθεσε το Access Key του αισθητήρα σου"
                                @input="handleChangeAccessKey($event)"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-dialog v-model="showLocationDialog" persistent max-width="600">
                <v-card>
                    <v-card-title class="justify-center">
                        {{ $t("project.sensors.locationDialogTitle") }}
                    </v-card-title>
                    <v-card-text class="text-center">
                        {{ $t("project.sensors.locationDialog") }}
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            depressed
                            color="garden"
                            class="white--text"
                            @click="closeLocationDialog()"
                        >
                            {{ $t("general.ok") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "ProjectSensors",

    props: {
        isValid: {
            type: Boolean,
            required: false,
            default: true,
        },

        hasSensor: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectedPlanting: {
            type: Object,
            required: false,
            default: null,
        },
        selectedSensor: {
            type: Object,
            required: false,
            default: null,
        },
        sensorLocation: {
            type: Object,
            required: false,
            default: null,
        },
        sensorAccess: {
            type: String,
            required: false,
            default: "",
        },
    },

    data() {
        return {
            select: this.selectedPlanting,
            addingSensor: !!this.selectedSensor,

            showLocationDialog: false,

            sensor: this.selectedSensor,
            location: this.sensorLocation,
            accessKey: this.sensorAccess,
            plantItems: [
                {
                    label: this.$t("project.sensors.fruitVeg"),
                    value: "fruit",
                },
                {
                    label: this.$t("project.sensors.flowers"),
                    value: "flowers",
                },
            ],

            sensors: [
                {
                    name: "Sensor-1",
                    type_id: 1,
                },
                {
                    name: "Sensor-2",
                    type_id: 2,
                },
            ],
        };
    },

    computed: {
        formIsValid() {
            if (this.addingSensor)
                return !!this.sensor && this.location && this.accessKey !== "";
            return !!this.select;
        },

        selectedFlowers() {
            if (!this.select) return true;
            return this.select.value === this.plantItems[1].value;
        },
    },

    methods: {
        handleChangePlant(event) {
            this.$emit("update:isValid", this.formIsValid);
            this.$emit("update:selectedPlanting", this.select);
        },
        handleChangeSensor(event) {
            this.$emit("update:isValid", this.formIsValid);
            this.$emit("update:selectedSensor", this.sensor);
        },

        handleChangeAccessKey(event) {
            this.$emit("update:isValid", this.formIsValid);
            this.$emit("update:sensorAccess", event);
        },

        async getGeolocation() {
            let coordinates;
            await navigator.geolocation.getCurrentPosition(
                (position) => {
                    coordinates = position.coords;
                    this.location = {
                        lat: coordinates.latitude,
                        lng: coordinates.longitude,
                    };
                    this.showLocationDialog = true;

                    this.$emit("update:sensorLocation", this.location);
                    this.$emit("update:isValid", this.formIsValid);
                    console.log(this.location);
                },
                (error) => {
                    console.log(error.message);
                    return;
                }
            );
        },

        closeLocationDialog() {
            this.showLocationDialog = false;
        },
    },

    watch: {
        addingSensor(newVal) {
            this.$emit("update:hasSensor", newVal);
            this.$emit("update:isValid", this.formIsValid);
        },

        select(newVal) {
            if (newVal === this.plantItems[1]) {
                this.addingSensor = false;
            }
        },

        selectedPlanting(newVal) {
            this.select = newVal;
        },
        selectedSensor(newVal) {
            this.sensor = newVal;
        },

        sensorLocation(newVal) {
            this.location = newVal;
        },
        sensorAccess(newVal) {
            this.accessKey = newVal;
        },
    },

    created() {
        if (!this.formIsValid) {
            this.$emit("update:isValid", false);
        }
    },
};
</script>

<style>
.v-select__selection--comma {
    font-size: 16px;
}
</style>
